@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-200%);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  to {
    opacity: 1;
    transform: translateY(-200%);
    visibility: hidden;
  }
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 160px;
  background-color: rgba(255, 255, 255, 0.5) !important;
  z-index: 9;
  opacity: 0;
  transition: opacity 350ms ease-in-out;
}
@media (max-width: 768px) {
  .ReactModal__Overlay {
    padding: 0;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  position: static !important;
  top: auto !important;
  padding: 0 !important;
  border: none !important;
  border-radius: 2px;
  display: flex;
  min-width: 280px;
  max-width: 768px;
  box-shadow: 0 0 36px rgba(155, 119, 119, 0.5);
  overflow: hidden !important;
}
.ReactModal__Content--after-open {
  animation: fadeInDown 350ms ease-in-out forwards;
}
.ReactModal__Content--before-close {
  animation: fadeOutUp 350ms ease-in-out forwards;
}
@media (max-width: 768px) {
  .ReactModal__Content {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    align-content: center;
    justify-content: center;
  }
}
.ReactModal__Content .body {
  overflow-y: auto;
}
